import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1562 1121" width="18" height="18">

    <g id="Layer">
      <path id="Layer" fill = '#f04e23' d="m780 896.8l-222.9 223.9-222.7-223.9 558.5-561-110.9-111.4 222.9-223.7 222.9 223.7-558.8 561z"/>
      <path id="Layer" fill = '#f04e23' d="m0.7 561.8l558.8-561.2 222.8 223.8-558.8 561.2z"/>
      <path id="Layer" fill = '#f04e23' d="m779.9 897.2l558.7-561.2 222.8 223.8-558.7 561.2z"/>
    </g>
  </svg>
  )
}

export function IconPAN(props)
{
  return <Icon component={IconSvg} {...props} />
}


