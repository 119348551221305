import React from 'react'
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Input,
  List,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  Drawer,
  Badge
} from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getKfeedInfoOrganisation,
  getUrlAssets,
  setSnFilterUrl,
} from "../../store/urlfilter";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import styles from "./UrlFilterNew.module.css";
import {
  CloseOutlined,
  SearchOutlined,
  CalendarOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {IconMarkFalse } from "../../icons";
import { HistoryOutlined } from '@ant-design/icons';
import Dot from "../UI/Dot";
import {
  getFallbackLogsLoader,
  getKfeedSucess,
  getOrgFallbacksInfo,
  getUrlAssetsData,
  getUrlLoaders,
} from "../../store/urlfilter/selectors";
import ModalConfirmURL from "./ModalConfirm";
//fallback-logs
import { getFallbackLogs } from '../../store/urlfilter';
import { getFallbackLogsInfo } from '../../store/urlfilter/selectors';


const { Text } = Typography;

function UrlAssets({ setSelectedTab, permissions }) {
  const dispatch = useDispatch();
  const isLoader = useSelector(getUrlLoaders);
  const assetsData = useSelector(getUrlAssetsData);
  const isSuccessSwitch = useSelector(getKfeedSucess);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [requestOrdering, setRequestOrdering] = useState(null);
  const [hitsOrdering, setHitsOrdering] = useState("");
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [switchFallbacks, setSwitchFallbacks] = useState(false);
  const fallbackLogsLoader = useSelector(getFallbackLogsLoader)
  const [fallbacksDrawerOpened, setFallbacksDrawerOpened] = useState(false);
  //фильтры
  const [dateAfterFilter, setDateAfterFilter] = useState(null);
  const [dateBeforeFilter, setDateBeforeFilter] = useState(null);
  const [viewDataFilter, setViewDataFilter] = useState(null);
  const fallbacksData = useSelector(getOrgFallbacksInfo);

  const fallbackLogs = useSelector(getFallbackLogsInfo)

  const getOrdering = (order) => {
    let ordering;
    switch (order?.value) {
      case "ascend":
        ordering = order?.type;
        break;
      case "descend":
        ordering = `-${order?.type}`;
        break;
      default:
        ordering = null;
    }
    return ordering;
  };

  const fetchData = () => {
    if (!isSuccessSwitch) {
      let filters = false;
      if (
        search ||
        dateAfterFilter ||
        dateBeforeFilter ||
        hitsOrdering ||
        requestOrdering
      ) {
        const order = hitsOrdering
          ? { type: "kfeed_hits_total", value: hitsOrdering }
          : requestOrdering
            ? { type: "hits_total", value: requestOrdering }
            : null;
        filters = {
          search: search || null,
          date_after: dateAfterFilter
            ?.split(" ")[0]
            .split(".")
            .reverse()
            .join("-"),
          date_before: dateBeforeFilter
            ?.split(" ")[0]
            .split(".")
            .reverse()
            .join("-"),
          ordering: getOrdering(order),
        };
      }

      dispatch(getUrlAssets({ filters, page: currentPage }));
    }
  };
  useEffect(() => {
    const timerRefresh = setTimeout(fetchData, 500);
    return () => {
      clearTimeout(timerRefresh);
    };
  }, [
    currentPage,
    search,
    requestOrdering,
    hitsOrdering,
    isSuccessSwitch,
    dateAfterFilter,
    dateBeforeFilter,
  ]);

  useEffect(() => {
    dispatch(getKfeedInfoOrganisation());
  }, []);



  const getDate = (date) => {
    const now = new Date();
    const formatDate = date.split(" ");
    const reverseDate =
      formatDate[0].split(".").reverse().join("-") + " " + formatDate[1];
    const reqDate = new Date(reverseDate);
    return now.getTime() - reqDate.getTime();
  };

  const getConnectionStatus = (date) => {
    if (getDate(date) < 86400000)
      return (
        <div title="Все хорошо" style={{ whiteSpace: "nowrap" }}>
          <Dot color="#219653" />
          <Text className={styles.cellText}>{date}</Text>
        </div>
      );
    else if (86400000 < getDate(date) && getDate(date) < 604800000)
      return (
        <div title="Не подключалось более 1 дня">
          <Dot color="#FFBC36" />
          <Text className={styles.cellText}>{date}</Text>
        </div>
      );
    else if (getDate(date) > 604800000)
      return (
        <div title="Не подключалось более 7 дней">
          <Dot color="#EB5757" />
          <Text className={styles.cellText}>{date}</Text>
        </div>
      );
  };
  const changeFallbacks = (_, row) => {
    setModalConfirmOpen({ open: true, item: row });
    setSwitchFallbacks(false);
  };

  const fallbackTableData = fallbackLogs?.results.map(item => ({
    key: fallbackLogs?.results?.indexOf(item),
    serial_nr: item.serial_nr,
    kfeed_mode: item.kfeed_mode,
    changed: item.updated_by ?
      (
        <div >
          {item.created_at}
          <br />
          {item.updated_by}
        </div>
      ) :
      (
        <div>
          {item.created_at}
          <br />
          {item.organisation}
        </div>
      ),
  }));

  const fallbackTableColumns = [
    createColumn("SN", "serial_nr"),
    createColumn("KFEED Mode", "kfeed_mode", (text) => {
      return (

        <Badge
          count={text.toUpperCase()}
          style={{
            background: text === 'monitoring' ? 'rgb(255,165,0, 0.1)' : 'rgb(224, 246, 234,0.3)',
            color: text === 'monitoring' ? 'orange' : 'rgb(33, 150, 83)',
            borderRadius: "4px",
            height: "26px",
            boxSizing: "border-box",
            padding: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100px",
            textAlign: "center",
          }} />
      )
    }
    ),
    createColumn('Изменено', 'changed')
  ]

  const columns = [
    createColumn("SN", "serial_nr"),
    createColumn("PLATFORM", "platform"),
    createColumn("CONNECTION", "last_active_at", (_, row) => {
      return (
        <Text style={{ fontSize: "10px" }}>
          {row["last_active_at"]
            ? getConnectionStatus(row["last_active_at"])
            : ""}
        </Text>
      );
    }),
    createColumn(
      "REQUESTS ",
      "hits_total",
      false,
      () => {
        return 0;
      },
      requestOrdering
    ),
    createColumn(
      "KFEED",
      "kfeed_hits_total",
      (text, row) => {
        return text > 0 ? (
          <Space style={{ display: 'flex', width: 100, justifyContent:'space-between'}}>
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                setSelectedTab("kfeed");
                dispatch(setSnFilterUrl(row["serial_nr"]));
              }}
            >
              {text}
            </span>
            <div
              style={{
                background: `#1677ff`,
                borderRadius: "4px",
                padding: "2px",
                width: "50px",
                display: "flex",
                justifyContent: "center",
                color: "white",
              }}
              onClick={() => {
                setSelectedTab("kfeed");
                dispatch(setSnFilterUrl(row["serial_nr"]));
              }}
            >
              <span>details</span>
            </div>
          </Space>
        ) : (
          text
        );
      },
      () => {
        return 0;
      },
      hitsOrdering
    ),

    createColumn("KFEED Mode", "fallbacks", (text, row) => {
      return (
        <Space
        style={{
          gap:'4px'
        }}
        >
          {switchFallbacks?.open &&
            switchFallbacks?.item.serial_nr === row["serial_nr"] ? (
            <Select
              style={{ width: "150px", lineHeight: "20px", height: "28px" }}
              variant="borderless"
              // value={row['fallbacks']?.length>0 ?1 : 0}
              defaultValue={row["fallbacks"]?.length > 0 ? 1 : 0}
              onChange={(e) => changeFallbacks(e, row)}
              options={[
                {
                  value: 0,
                  label: (
                    <div
                      style={{
                        fontSize: "10px",
                        //border: '1px solid orange',
                        background: `rgb(255,165,0, 0.1)`,
                        borderRadius: "4px",
                        height: "26px",
                        boxSizing: "border-box",
                        padding: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100px",
                        textAlign: "center",
                        color: "orange",
                      }}
                    >
                      MONITORING
                    </div>
                  ),
                },
                {
                  value: 1,
                  label: (
                    <div
                      style={{
                        fontSize: "10px",
                        //border: '1px solid orange',
                        background: "rgb(224, 246, 234,0.3)",
                        borderRadius: "4px",
                        height: "26px",
                        boxSizing: "border-box",
                        padding: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100px",
                        textAlign: "center",
                        color: "rgb(33, 150, 83)",
                      }}
                    >
                      REPLACING
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            <div
              style={{
                fontSize: "10px",
                background:
                  row["fallbacks"]?.length > 0
                    ? "rgb(224, 246, 234, 0.3)"
                    : `rgb(255,165,0, 0.1)`,
                borderRadius: "4px",
                padding: "0 20px 0 11px",
                height: "26px",
                width: `${switchFallbacks ? "100px" : "150px"}`,
                boxSizing: "border-box",
                margin: `${switchFallbacks ? "0 0 0" : "0"}`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color:
                  row["fallbacks"]?.length > 0 ? "rgb(33, 150, 83)" : "orange",
              }}
            >
              {row["fallbacks"]?.length > 0 ? "REPLACING" : "MONITORING"}
            </div>
          )}
          <div style={{display:'flex', gap: '5px'}}>
          {switchFallbacks?.open &&
            switchFallbacks?.item.serial_nr === row["serial_nr"] ? (
            <Tooltip
              title={<span style={{ color: "#4B4F55" }}>Отменить</span>}
              color="#FFFFFF"
            >
            
                <IconMarkFalse onClick={() => setSwitchFallbacks(false)} style={{fontSize: 18, alignSelf:'end', color: '#8090B8'}} />
            </Tooltip>
          ) : (
            permissions?.full_access && (
             row["urlf_can_set_fallbacks"] && <Tooltip
                title={<span style={{ color: "#4B4F55" }}>Редактировать</span>}
                color="#FFFFFF"
              >
                  <EditOutlined style={{fontSize: 18, alignSelf:'end', color: '#8090B8'}}  onClick={() => setSwitchFallbacks({ open: true, item: row })}/>
          
              </Tooltip>
            )
            
          )}
          <Tooltip
              title={<span style={{ color: "#4B4F55" }}>Лог переключения режима kfeed</span>}
              color="#FFFFFF"
            >
            
                <HistoryOutlined style={{fontSize:18, alignSelf:'end', color: '#8090B8'}}                 onClick={() => {
                  setFallbacksDrawerOpened(true); setSerialNumber(row['serial_nr']);
                  setPageNumber(1); dispatch(getFallbackLogs({ serialNumber: row['serial_nr'], page: 1 }))
                }}/>
  
            </Tooltip>
            </div>
        </Space>
      );
      
    }),
    createColumn("COMPANY", "organisation"),
  ];




  const handleChangeDateAfter = (e) => {
    if (e?.$d) {
      setDateAfterFilter(e?.$d.toLocaleString("ru-RU").replace(",", ""));
      if (dateBeforeFilter) {
        setViewDataFilter(
          e?.$d.toLocaleString("ru-RU").replace(",", "") +
          "-" +
          dateBeforeFilter
        );
      } else
        setViewDataFilter(
          e?.$d.toLocaleString("ru-RU").replace(",", "") + " - "
        );
    } else setDateAfterFilter(null);
  };
  const handleChangeDateBefore = (e) => {
    if (e?.$d) {
      setDateBeforeFilter(e?.$d.toLocaleString("ru-RU").replace(",", ""));
      if (dateAfterFilter) {
        setViewDataFilter(
          dateAfterFilter +
          " - " +
          e?.$d.toLocaleString("ru-RU").replace(",", "")
        );
      } else
        setViewDataFilter(
          " - " + e?.$d.toLocaleString("ru-RU").replace(",", "")
        );
    } else setDateBeforeFilter(null);
  };
  const calculateYesterday = () => {
    let today = new Date();
    today.setDate(today.getDate() - 1);
    return today;
  };
  const calculateMonth = () => {
    let date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  };
  const frequency = {
    today: {
      numberDate: new Date().setHours(0, 0, 0, 0),
      title: "Сегодня",
    },

    yesterday: {
      numberDate: calculateYesterday(),
      title: "Вчера",
    },

    "30d": {
      numberDate: new Date().getTime() - 2592000000,
      title: "За последние 30 дней",
    },
    // '1y': {
    //     numberDate: (new Date()).getTime() - 31536000000,
    //     title: 'За последний год'
    // },
    "1y": {
      numberDate: new Date().getTime() - 31536000000,
      title: "За последний год",
    },
  };

  const dateFormat = "YYYY-MM-DD";

  const resetDateFilterBefore = (value) => {
    const date =
      value?.split(" ")[0].split(".").reverse().join("-") +
      " " +
      value?.split(" ")[1];
    return dayjs(date, dateFormat);
  };
  const resetDateFilterAfter = (value) => {
    const date =
      value?.split(" ")[0]?.split(".")?.reverse().join("-") +
      " " +
      value?.split(" ")[1];
    return dayjs(date, dateFormat);
  };
  const frequentlyUsedFilters = [];

  Object.keys(frequency).forEach((key, i) => {
    frequentlyUsedFilters.push(
      <Button
        style={{ background: "transparent", border: "none" }}
        key={i}
        disabled={
          key === "1y" && !fallbacksData?.has_fallbacks_enabled ? true : false
        }
        onClick={() => handleSetDateFilters(key)}
      >
        {frequency[key].title}
      </Button>
    );
  });
  useEffect(() => {
    if (fallbacksData) {
      handleSetDateFilters("today");
    }
  }, [fallbacksData]);

  const handleSetDateFilters = (value) => {
    if (frequency[value]) {
      // setDateFrequentlyFilter(value);
      setViewDataFilter(frequency[value].title);
      const actualDate = new Date(frequency[value].numberDate);
      setDateAfterFilter(actualDate.toLocaleString("ru-RU").replace(",", ""));
    }

    setOpenDate(false);
  };
  const content = (
    <List
      size="small"
      header={false}
      footer={
        <div>
          <hr />
          <Space direction="vertical">
            <span>Начало</span>
            <DatePicker
              renderExtraFooter={() => null}
              footer={null}
              format="DD-MM-YYYY"
              onChange={handleChangeDateAfter}
              showNow={false}
              showToday={false}
              placeholder="Выберите дату начала"
              disabledDate={(d) => {
                return !fallbacksData?.has_fallbacks_enabled
                  ? !d || d.isBefore(calculateMonth()) || d.isAfter(new Date())
                  : !d || d.isBefore("2024-01-01") || d.isAfter(new Date());
              }}
              //   return d &&  d.isAfter(calculateYesterday()) && d.isBefore("2024-01-01")}}
              value={
                dateAfterFilter ? resetDateFilterAfter(dateAfterFilter) : null
              }
            />
            <span>Окончание</span>
            <DatePicker
              showNow={false}
              showToday={false}
              renderExtraFooter={() => null}
              format="DD-MM-YYYY"
              disabledDate={(d) => {
                return !fallbacksData?.has_fallbacks_enabled
                  ? !d || d.isBefore(calculateMonth()) || d.isAfter(new Date())
                  : !d || d.isBefore("2024-01-01") || d.isAfter(new Date());
              }}
              onChange={handleChangeDateBefore}
              placeholder="Выберите дату окончания"
              value={
                dateBeforeFilter
                  ? resetDateFilterBefore(dateBeforeFilter)
                  : null
              }
            />
          </Space>
        </div>
      }
      className={styles.list}
      dataSource={frequentlyUsedFilters}
      renderItem={(item) => (
        <List.Item
          className={styles.listItem}
          style={{
            cursor: "pointer",
            border: "none",
          }}
        >
          {item}
        </List.Item>
      )}
    />
  );
  const handleOpenChangeDate = () => setOpenDate(!openDate);


  const [pageNumber, setPageNumber] = useState(null)
  const [serialNumber, setSerialNumber] = useState(null)
  const handlePageChange = (page) => {
    setPageNumber(page)
    dispatch(getFallbackLogs({ serialNumber, page }))
  }


  return (
    <Space
      direction="vertical"
      size={20}
      style={{ width: "100%", position: "relative" }}
    >
      <Drawer
        closable
        width={700}
        placement="right"
        open={fallbacksDrawerOpened}
        title='Лог переключения режима KFEED'
        onClose={() => { setFallbacksDrawerOpened(false) }}
      >
        <Table
         loading={{ indicator: <PreloaderNew />, spinning: fallbackLogsLoader }}
          columns={fallbackTableColumns}
          className={styles.table}
          dataSource={fallbackTableData}
          rowKey={(row) => row.key}
          pagination={{
            showTotal: total => `Всего ${total}`,
            current: pageNumber,
            defaultPageSize: 10,
            onChange: handlePageChange,
            total: fallbackLogs?.count
          }}
        />
      </Drawer>
      {
        <ModalConfirmURL
          open={modalConfirmOpen?.open}
          close={() => setModalConfirmOpen(false)}
          item={modalConfirmOpen.item}
        />
      }
      <Row justify="space-between">
        <Col style={{ display: 'flex', gap: '15px', alignItems: 'flex-start' }} >
          <Input
            prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
            placeholder="Поиск по SN"
            onChange={(evt) => {
              setCurrentPage(1);
              setSearch(evt.target.value.trim());
            }}
            value={search}
            suffix={
              <CloseOutlined
                style={
                  search
                    ? {
                      color: "red",
                    }
                    : {
                      color: "black",
                    }
                }
                onClick={() => {
                  setCurrentPage(1);
                  setSearch("");
                }}

              />
            }
            style={{
              color: "black",
              width: "356px",
              height: 32,
              padding: "8px 12px",
            }}
          />

          <Space.Compact
            style={{ alignSelf: "flex-end" }}
            title="Выберите интервал"
          >
            <Popover
              placement="bottomLeft"
              open={openDate}
              onOpenChange={handleOpenChangeDate}
              title={false}
              content={content}
              trigger="click"
            >
              <Button
                className={styles.dashboardInput}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CalendarOutlined
                  style={{
                    padding: "5px 0",
                    color: "#6679A9",
                  }}
                />
                <span style={{ padding: "0 5px", fontSize: 12 }}>
                  {viewDataFilter}
                </span>
              </Button>
            </Popover>
          </Space.Compact>
        </Col>
        {/* <Col>
				 <DatePicker onChange={onChangeDateAfter} placeholder='Дата после' value={dateAfterCurrent} allowClear={true}/>
				 </Col>
				 <Col>
				 <DatePicker onChange={onChangeDateBefore} placeholder='Дата до' value={dateBeforeCurrent} allowClear={true}/>
				 </Col> */}
        <Col>{(search ||
          dateAfterFilter !== dayjs(new Date(frequency.today.numberDate)).$d.toLocaleString("ru-RU").replace(",", "") ||
          dateBeforeFilter ||
          requestOrdering ||
          hitsOrdering) && (
            <Button
              type="default"
              style={{ marginLeft: "5px" }}
              onClick={() => {
                setCurrentPage(1);
                setSearch("");
                setRequestOrdering(null);
                setHitsOrdering(null);
                handleSetDateFilters("today");
              }}
            >
              Сбросить
            </Button>
          )}
        </Col>
      </Row>
      <Table
       loading={{ indicator: <PreloaderNew />, spinning: isLoader }}
        locale={{
          triggerDesc: "сортировать по убыванию",
          triggerAsc: "сортировать по возрастанию",
          cancelSort: "по умолчанию",
        }}
        onHeaderRow={() => {
          return {
            onClick: (e) => {
              if (e.target.innerText === "REQUESTS") {
                setHitsOrdering(null);
                switch (requestOrdering) {
                  case "ascend":
                    setRequestOrdering("descend");
                    break;
                  case "descend":
                    setRequestOrdering(null);
                    break;
                  default:
                    setRequestOrdering("ascend");
                }
              }
              if (e.target.innerText === "KFEED") {
                setRequestOrdering(null);
                switch (hitsOrdering) {
                  case "ascend":
                    setHitsOrdering("descend");
                    break;
                  case "descend":
                    setHitsOrdering(null);
                    break;
                  default:
                    setHitsOrdering("ascend");
                }
              }
            },
          };
        }}
        columns={columns}
        size="small"
        rowKey={(obj) => obj.serial_nr}
        pagination={{
          position: "bottomRight",
          current: currentPage,
          defaultPageSize: 20,
          total: assetsData?.count,
          onChange: (page) => setCurrentPage(page),
        }}
        style={{ overflowX: "auto" }}
        className={styles.table}
        dataSource={assetsData?.results}
      />
    </Space>
  );
}

function createColumn(title, key, render, sorter, sortOrder) {
  return { title, key, dataIndex: key, render, sorter, sortOrder };
}
export default UrlAssets;
