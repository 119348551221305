import { Alert, Button, Drawer, Rate, Space, Tooltip, Typography } from "antd";
import React, { } from "react";
import { IconClose } from "../../icons";
import {  useSelector } from "react-redux";
//import { getUrlKfeedDetail } from "../../store/urlfilter";
import {
  getUrlCheckErrors,
  //getUrlKfeedDataDetail,
} from "../../store/urlfilter/selectors";
const { Title, Text } = Typography;
const grayText = { color: "#7A7D86" };

function UrlItemModal({ open, close, item, mask, url, data }) {
  //const dispatch = useDispatch();
  //const data = useSelector(getUrlKfeedDataDetail);
  const errors = useSelector(getUrlCheckErrors);

  // useEffect(() => {
  //   if (mask && !mask?.includes("*")) {
  //     dispatch(
  //       getUrlKfeedDetail({ param: "mask", value: mask, urlKfeed: item?.url })
  //     );
  //   } else if (url && !url?.includes("*")) {
  //     dispatch(getUrlKfeedDetail({ param: "url", value: url }));
  //   } else
  //     dispatch(getUrlKfeedDetail({ param: "stats_id", value: item.stats_id }));
  // }, [item, mask]);

  return (
    <Drawer
      open={open}
      onClose={close}
      width={700}
      styles={{ header: { display: "none" } }}
    >
      <Space
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingInline: 48,
          height: 64,
          zIndex: 10,
        }}
      >
        <Title
          level={3}
          style={{
            margin: 0,
          }}
        >
          {url || mask || item?.url || ""}
        </Title>
        <Button
          onClick={close}
          icon={<IconClose style={{ width: 12, height: 12 }} />}
          type="ghost"
          style={{
            width: 32,
            height: 32,
            backgroundColor: "#F6F8FD",
            padding: 0,
            marginRight: "-32px",
          }}
        />
      </Space>

      <Space
        direction="vertical"
        size={24}
        style={{
          paddingInline: 48,
          paddingBottom: 32,
          display: "flex",
        }}
      >
        {errors && (
          <Alert
            message={"В текущей базе угроз Kaspersky запись не найдена"}
            style={{ fontSize: "20px" }}
            type="error"
            showIcon
          />
        )}
        {!errors && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "100px auto",
              gap: 8,
              backgroundColor: "#F6F8FD",
              borderRadius: 8,
              padding: 24,
              fontSize: "12px",
            }}
          >
            {data?.receipted_at && (
              <Text style={grayText}>По данным {<br />}kaspersky от: </Text>
            )}
            {data?.receipted_at && <Text>{data?.receipted_at}</Text>}
            {data?.attack_type && <Text style={grayText}>Attack type</Text>}
            {data?.attack_type && <Text>{data?.attack_type}</Text>}
            {data && <Text style={grayText}>Categories</Text>}
            {data ? (
              <div>
                {data?.categories?.map((item, index) => (
                  <span
                    key={item.cat_id + "cat"}
                    style={{ fontSize: 14, color: "red", fontWeight: 600 }}
                  >
                    {/* {item.name + ' '} */}
                    {item.name}
                    {index < data?.categories?.length - 1 ? ", " : ""}&nbsp;
                  </span>
                ))}
              </div>
            ) : (
              ""
            )}
            {data?.pa_categories?.length > 0 && (
              <Text style={grayText}>PA Categories</Text>
            )}
            {data?.pa_categories?.length > 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                    color: "green",
                    fontWeight: 600,
                  }}
                >
                  {data?.pa_categories?.map((item, index) => (
                    <span key={item + index}>
                      {item}
                      {index < data?.pa_categories?.length - 1 ? ", " : ""}
                      &nbsp;
                    </span>
                  ))}
                </div>
                <br /> <span>Обновлено: {data.updated_at || "Нет данных"}</span>
              </>
            )}
            {data?.files?.length > 0 && <Text style={grayText}>Files</Text>}
            {data?.files?.length > 0 && (
              <div style={{ fontSize: 14 }}>
                {" "}
                {data?.files?.map((item, index) => (
                  <div key={index + "files"} style={{ fontSize: "10px" }}>
                    {" "}
                    <span>
                      MD5: {item.MD5}
                      <br />
                    </span>
                    <span>
                      SHA1: {item.SHA1}
                      <br />
                    </span>
                    <span>
                      SHA256:{item.SHA256}
                      <br />
                    </span>
                    <span>
                      Threat: {item.threat}
                      <br />
                    </span>
                  </div>
                ))}
              </div>
            )}
            {data?.last_seen && <Text style={grayText}>Last seen</Text>}
            {data?.last_seen && <Text>{data?.last_seen}</Text>}
            {data?.first_seen && <Text style={grayText}>First seen</Text>}
            {data?.first_seen && <Text>{data?.first_seen}</Text>}

            {data?.geo?.length > 0 && <Text style={grayText}>Geo</Text>}
            {data?.geo?.length > 0 && (
              <div>
                {" "}
                {data?.geo?.map((item) => (
                  <span key={item} style={{ fontSize: 14 }}>
                    {item}
                    <br />
                  </span>
                ))}
              </div>
            )}
            {data?.industry?.length > 0 && (
              <Text style={grayText}>Industry</Text>
            )}
            {data?.industry?.length > 0 && (
              <div style={{ fontSize: 14 }}>
                {" "}
                {data?.industry?.map((item) => (
                  <span key={item}>
                    {item}
                    <br />
                  </span>
                ))}
              </div>
            )}
            {data?.ip?.length > 0 && <Text style={grayText}>IP</Text>}
            {data?.ip?.length > 0 && (
              <div style={{ fontSize: 14 }}>
                {" "}
                {data?.ip?.map((item) => (
                  <span key={item}>
                    {item}
                    <br />
                  </span>
                ))}
              </div>
            )}

            {data && data?.phishing_kit !== "[]" && (
              <Text style={grayText}>Phishing Kit</Text>
            )}
            {data && data?.phishing_kit !== "[]" && (
              <Text>{data?.phishing_kit}</Text>
            )}
            {data?.popularity && (
              <div style={{ display: "flex", gap: 5 }}>
                <Text style={grayText}>Popularity</Text>
                <Tooltip
                  color="#FFFFFF"
                  title={
                    <span style={{ color: "#4B4F55" }}>
                      Популярность — индекс, определяющий популярность записи
                      (на сколько пользователей повлияла эта запись). Самый
                      популярный индекс&nbsp;5, а наименее популярный&nbsp;1.
                    </span>
                  }
                >
                  <div
                    // title={'популярность — индекс, определяющий популярность записи (на сколько пользователей повлияла эта запись). Самый популярный индекс 5, а наименее популярный  1. Записи в фидах сортируются в порядке убывания значений этого поля.'
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 14,
                      height: 14,
                      borderRadius: "50%",
                      border: "1px solid #7A7D86",
                      color: "#7A7D86",
                      cursor: "pointer",
                    }}
                  >
                    ?
                  </div>
                </Tooltip>
              </div>
            )}
            {data ? (
              data.popularity === "0" ? (
                <Text>'Нет данных'</Text>
              ) : (
                <Rate disabled value={data?.popularity} />
              )
            ) : (
              // <div style={{display: "flex", alignItems: "flex-end", height: '40px', width: '100%'}}>

              // <div className={styles.gradient}>
              //     <div style={{position: "absolute", top: '-38px', left: `${((data?.popularity -1) * 25)-2}%`, display: "flex", flexDirection: "column", alignItems: "center", color: getColor(data?.popularity) }}>
              //         <span>{data?.popularity}</span><div className={styles.triangle}></div></div></div>
              // </div>
              ""
            )}

            {data?.stolen_data_type?.length > 0 && (
              <Text style={grayText}>Stolen Data type</Text>
            )}
            {data?.stolen_data_type?.length > 0 && (
              <div style={{ fontSize: 14 }}>
                {" "}
                {data?.stolen_data_type?.map((item, index) => (
                  <div key={item + index}>
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            )}
            {data?.targeted_organization && (
              <Text style={grayText}>Targeted organization</Text>
            )}
            {data?.targeted_organization && (
              <Text>{data?.targeted_organization}</Text>
            )}
            {/* {data?.type && <Text style={grayText}>Type</Text>}
					  {data?.type && <Text>{data?.type}</Text>} */}
            {/* {data?.whois && <Text style={grayText}>Whois</Text>}
					  {data?.whois &&<div> <div style={{ fontSize: "12px" }}>
                                    {" "}
                                    <span>
                                        MX: {data?.whois.MX}
                                        <br />
                                    </span>
                                    <span>
                                        MX ips: {data?.whois.MX_ips}
                                        <br />
                                    </span>
                                    <span>
                                        NS: {data?.whois.NS}
                                        <br />
                                    </span>
                                    <span>
                                        NS ips: {data?.whois.NS_ips}
                                        <br />
                                    </span>
                                    <span>
                                        City: {data?.whois.city}
                                        <br />
                                    </span>
                                    <span>
                                        Country: {data?.whois.country}
                                        <br />
                                    </span>
                                    <span>
                                        Created: {data?.whois.created}
                                        <br />
                                    </span>
                                    <span>
                                        Domain: {data?.whois.domain}
                                        <br />
                                    </span>
                                    <span>
                                        Email: {data?.whois.email}
                                        <br />
                                    </span>
                                    <span>
                                        Expires: {data?.whois.expires}
                                        <br />
                                    </span>
                                    <span>
                                        Name: {data?.whois.name}
                                        <br />
                                    </span>
                                    <span>
                                        Org: {data?.whois.org}
                                        <br />
                                    </span>
                                    <span>
                                        Registrar email:{" "}
                                        {data?.whoisregistrar_email}
                                        <br />
                                    </span>
                                    <span>
                                        Registrar name:{" "}
                                        {data?.whois.registrar_name}
                                        <br />
                                    </span>
                                    <span>
                                        Updated: {data?.whois.updated}
                                        <br />
                                    </span>
                                 
                                </div></div>} */}
          </div>
        )}
      </Space>
    </Drawer>
  );
}

export default UrlItemModal;
