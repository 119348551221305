import axios from "axios";
// import  stringify  from 'query-string';

let baseUrlClear = "/";
if (process.env.REACT_APP_API_SERVER === "localhost:8000") {
  baseUrlClear = `http://${process.env.REACT_APP_API_SERVER}`;
} else {
  baseUrlClear = `https://${process.env.REACT_APP_API_SERVER}`;
}

const axiosInstance = axios.create({
  baseURL: baseUrlClear,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessTokenPC");
    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

export { axiosInstance };
