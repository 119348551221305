import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../auth";
const typeFileApp = (value) => {
  let type = "";
  switch (value) {
    case "txt":
      type = "";
      break;
    case "zip":
      type = "application/zip";
      break;
    case "xlsx":
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;";
      break;
    case "xls":
      type = "application/vnd.ms-excel";
      break;
    case "doc":
      type = "application/msword";
      break;
    case "docx":
      type =
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      break;
    default:
      type = `application/${value}`;
  }
  return type;
};
const checkFilters = (filters, page) => {
  if (!filters) filters = {};
  filters.page = page;
  return filters;
};
export const getSandboxList = createAsyncThunk(
  "getSandboxList",
  async ({ filters, page, pagination }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/v1/sandbox/checked-files-data/`,
        { params: checkFilters(filters, page) }
      );
      return { data, pagination };
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else return rejectWithValue(err.response.data);
    }
  }
);

export const getSandboxStats = createAsyncThunk(
  "getSandboxStatsl",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `api/v1/sandbox/checked-files-metrics/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getSandboxMyAssets = createAsyncThunk(
  "getSandboxMyAssets",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`api/v1/sandbox/my-assets/`);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getDocFileSandBox = createAsyncThunk(
  "getDocFileSandBox",
  async ({ hash, name, typeFile, source }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(`api/v1/sandbox/files/`, {
        params: { file_hash: hash, source },
        responseType: "blob",
      });
      return { data, name, typeFile };
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else if (err.response.status === 404)
        return rejectWithValue({
          message: "К сожалению запрашиваемый отчет больше не существует",
        });

      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  sandboxData: null,
  sandboxStats: null,
  sandboxAssets: [],
  nextPage: null,
  fileError: null,
  errors: null,
  loaders: {
    common: false,
  },
};

const sandboxSlice = createSlice({
  name: "sandbox",
  initialState,
  reducers: {
    resetNextPage: (state) => {
      state.nextPage = null;
    },
    resetUpdUrlData: (state) => {
      state.updatedUrlData = null;
    },
  },

  extraReducers: (builder) => {
    //getSandboxList
    builder.addCase(getSandboxList.pending, (state) => {
      state.loaders.common = true;
      state.fileError = null;
    });
    builder.addCase(getSandboxList.fulfilled, (state, { payload }) => {
      if (payload?.data?.items?.length > 0) {
        if (payload.pagination) {
          state.sandboxData.push(...payload?.data?.items);
          state.nextPage = payload.data?.page;
        } else {
          state.sandboxData = payload.data?.items;
          state.nextPage = payload.data?.page;
        }
      } else {
        state.nextPage = null;
        state.sandboxData = payload?.pagination ? state.sandboxData : [];
      }
      state.errors = null;
      state.loaders.common = false;
    });
    builder.addCase(getSandboxList.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors =
        action?.payload?.detail || action?.payload?.error || "Ошибка сервера";
    });

    //getSandboxStats
    builder.addCase(getSandboxStats.pending, (state) => {
      // state.loaders.common = true;
      state.fileError = null;
    });
    builder.addCase(getSandboxStats.fulfilled, (state, { payload }) => {
      state.sandboxStats = payload;
      //state.loaders.common = false;
      state.errors = null;
    });
    builder.addCase(getSandboxStats.rejected, (state, action) => {
      state.loaders.common = false;
      // state.errors = action.payload.detail ? action.payload.detail : action.payload.error;
    });
    //getDocFileSandBox
    builder.addCase(getDocFileSandBox.pending, (state) => {
      state.loaders.common = true;
      state.fileError = null;
      // state.loaders.external = true;
      // state.errors.getError = null;
    });
    builder.addCase(getDocFileSandBox.fulfilled, (state, { payload }) => {
      const blob = new Blob([payload.data], {
        type: typeFileApp(payload.typeFile),
      });
      // const blob = new Blob([payload.data], { type: `application/${payload.typeFile}` });
      const fileUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.setAttribute("href", fileUrl);
      link.setAttribute("download", `${payload.name}.pdf`);
      link.style.display = "none";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      state.loaders.common = false;
    });
    builder.addCase(getDocFileSandBox.rejected, (state, action) => {
      state.loaders.common = false;
      state.fileError =
        action.payload.detail || action.payload.message || "Ошибка сервера";
      // state.loaders.external = false;
    });
    //getSandboxMyAssets
    builder.addCase(getSandboxMyAssets.pending, (state) => {
      state.loaders.common = true;
      state.fileError = null;
    });
    builder.addCase(getSandboxMyAssets.fulfilled, (state, { payload }) => {
      state.sandboxAssets = payload;
      state.loaders.common = false;
      state.errors = null;
    });
    builder.addCase(getSandboxMyAssets.rejected, (state, action) => {
      state.loaders.common = false;
      // state.errors = action.payload.detail ? action.payload.detail : action.payload.error;
    });
  },
});
export const { resetNextPage } = sandboxSlice.actions;
export default sandboxSlice.reducer;
