import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Table,
  Badge,
  message,
  Input,
  Select,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";

import { IconCopy, IconDownloadFile, IconSearch } from "../../icons";

import { getDocFileSandBox, resetNextPage } from "../../store/sandbox";
import {
  getSandboxData,
  getSandboxMyAssetsDrop,
} from "../../store/sandbox/selectors";
import { IconPAN } from "../../icons/IconPAN";
import { IconForti } from "../../icons/IconForti";

function SandboxList({
  setSearch,
  setVerdict,
  search,
  verdict,
  renew,
  setRenew,
  fileType,
  setFileType,
  serial,
  setSerial,
  source,
  setSource,
}) {
  const [messageApi] = message.useMessage();
  const dispatch = useDispatch();

  const assetsData = useSelector(getSandboxData);
  const [copyText, setCopyText] = useState(null);
  const myAssetsDrop = useSelector(getSandboxMyAssetsDrop);
  const shouldDisplayButton = true;

  const copyBufferTextTable = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyText({ text: "Copy", field: "table" });
      })
      .catch(() => {
        setCopyText({ text: "Error", field: "table" });
      });
  };

  useEffect(() => {
    if (copyText) messageApi.info(copyText.text);
  }, [copyText]);

  function renderMultilineTitle(title) {
    return (
      <Space size={0} direction="vertical">
        <span>{title}</span>
      </Space>
    );
  }

  const viewVerdict = (verdict) => {
    let color;
    switch (verdict) {
      case "Malware":
        color = "red";
        break;
      case "Benign":
        color = "lime";
        break;
      default:
        color = "grey";
    }
    return color;
  };
  const changeDate = (date) => {
    if (date) {
      const formatDate = date?.split("T");
      const reverseDate =
        formatDate[0].split("-").reverse().join(".") +
        " " +
        formatDate[1].split(".")[0];
      return reverseDate;
    } else return null;
  };
  const columns = [
    createColumn(
      renderMultilineTitle("Check DateTime (UTC)", "Ordered Desc"),
      "checked_dt",
      (text) => changeDate(text)
    ),
    createColumn("SN", "firewall_id"),
    createColumn("Platform", "platform_name", (text) => text || "Нет данных"),
    createColumn("SHA256", "file_hash", (text) => (
      <div title={text}>
        ...{text.slice(-35)}
        <Button
          size="small"
          type="text"
          icon={<IconCopy style={{ color: "#8090B8" }} />}
          onClick={() => {
            copyBufferTextTable(text);
          }}
        />
      </div>
    )),
    createColumn("File Type", "file_type"),
    createColumn("Verdict", "verdict", (text) => (
      <Badge count={text} showZero color={viewVerdict(text)} />
    )),
    createColumn("Source", "source", (text) => (
      <Tooltip title={text === "forti" ? "Fortinet" : "Palo Alto Networks"}>
        {text === "forti" ? <IconForti /> : <IconPAN />}
      </Tooltip>
    )),
    createColumn("Report", "file_link", (text, row) => {
      return (
        text && (
          <Button
            size="small"
            type="text"
            icon={<IconDownloadFile style={{ color: "#8090B8" }} />}
            onClick={() => {
              downloadFile(row);
            }}
            title={row["file_hash"]}
          ></Button>
        )
      );
    }),
  ];
  const clearFilters = () => {
    setFileType(null);
    setSearch("");
    setVerdict(null);
    setSerial(null);
    setSource(null);
  };

  const downloadFile = async (file) => {
    let stringType = file.file_link.slice(-5);
    let index = stringType.lastIndexOf(".");
    dispatch(
      getDocFileSandBox({
        hash: file.file_hash,
        name: file.firewall_id,
        typeFile: stringType.slice(index + 1),
        source: file.source,
      })
    );
  };
  return (
    <Space
      direction="vertical"
      size={20}
      style={{ width: "100%", position: "relative", marginTop: "10px" }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Space align="start">
          <Input
            prefix={<IconSearch style={{ color: "#C1C2C9" }} />}
            value={search}
            disabled={assetsData ? false : true}
            placeholder="Поиск по hash"
            onChange={(e) => {
              dispatch(resetNextPage());
              setSearch(e.target.value);
            }}
          />
          <Select
          data-testid='serialN-selector'
            style={{ width: "175px" }}
            onChange={(e) => {
              dispatch(resetNextPage());
              setSerial(e);
            }}
            placeholder="Серийник"
            //defaultValue={''}
            value={serial}
            options={
              myAssetsDrop?.map((item) => {
                return { value: item.firewall_id, label: item.firewall_id };
              })
              //{ value: '', label: "Выберите вердикт" },
              // { value: "Malware", label: "Malware" },
              // { value: "Benign", label: "Benign" },
            }
          />
          <Select
          data-testid='verdict-selector'
            style={{ width: "200px" }}
            mode={"multiple"}
            value={verdict || []}
            onChange={(e) => {
              dispatch(resetNextPage());
              setVerdict(e);
            }}
            placeholder="Вердикт"
            //defaultValue={''}
            options={[
              //{ value: '', label: "Выберите вердикт" },
              { value: 1, label: "Malware" },
              { value: 2, label: "Grayware" },
              { value: "0", label: "Benign" },
            ]}
          />
          <Select
          data-testid='source-selector'
            style={{ width: "120px" }}
            onChange={(e) => {
              dispatch(resetNextPage());
              setSource(e);
            }}
            placeholder="Источник"
            //defaultValue={''}
            value={source}
            options={[
              //{ value: '', label: "Выберите вердикт" },
              { value: "forti", label: "Fortinet" },
              { value: "palo_alto", label: "Palo Alto Networks" },
            ]}
          />
          <Select
          data-testid='type-selector'
            style={{ width: "200px" }}
            mode={"multiple"}
            onChange={(e) => {
              dispatch(resetNextPage());
              setFileType(e);
            }}
            placeholder="Тип файла"
            value={fileType || []}
            options={[
              { value: "pdf", label: "pdf" },
              { value: "ms-office", label: "ms-office" },
              { value: "pe", label: "pe" },
              { value: "jar", label: "jar" },
              { value: "flash", label: "flash" },
              { value: "archive", label: "archive" },
              { value: "script", label: "script" },
              { value: "apk", label: "apk" },
              { value: "linux", label: "linux" },
            ]}
          />
          {(verdict || search || fileType || serial || source) && (
            <Button
              style={{
                backgroundColor: "transparent",
                display: !shouldDisplayButton ? "none" : "",
              }}
              onClick={() => clearFilters()}
            >
              Сбросить
            </Button>
          )}
        </Space>
        <Button type="primary" onClick={() => setRenew(!renew)}>
          Обновить
        </Button>
      </div>

      {assetsData && (
        <Table
          columns={columns}
          rowKey={(obj) => obj.file_hash + obj.firewall_id}
          size="small"
          pagination={false}
          style={{ overflowX: "auto" }}
          className="table"
          dataSource={assetsData}
        />
      )}
    </Space>
  );
}

function createColumn(title, key, render) {
  return { title, key, dataIndex: key, render };
}

export default SandboxList;
