import React from "react";
import { Button,  Modal, } from "antd";
import { IconClose } from "../../icons";
import PatchEdlLInstanceNew from "./PatchEdlLInstanceNew ";


function ModaLPatchInctance({ open, close, item, list }) {

	return (
		<Modal
		open={open}
		closable={false}
		footer={null}
		centered

	>
		<Button
			onClick={close}
			icon={<IconClose style={{ width: 12, height: 12 }} />}
			type="ghost"
			style={{
				position: "absolute",
				right: 16,
				top: 16,
				width: 32,
				height: 32,
				backgroundColor: "#F6F8FD",
				padding: 0,
			}}
		/>
		<PatchEdlLInstanceNew close={close} item={item} list={list}/>
		</Modal>
	)
}



export default ModaLPatchInctance;
