import React from "react";
import { Drawer, Space, Typography } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import {
  getDisDetailLoader,
  getDistributionsServerDetails,
} from "../../store/distributions/selectors";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";

export const DistributionsServerDetails = ({ open, close }) => {
  const loader = useSelector(getDisDetailLoader);
  const dataDetail = useSelector(getDistributionsServerDetails);

  function parseReleaseNotes(releaseNotes) {
    const entries = releaseNotes?.trim().split("*");
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {entries
          ?.filter((entry) => entry?.trim() !== "")
          ?.map((entry, index) => (
            <Space
              style={{
                padding: "12px 16px",
                borderBottom: "1px solid #E9EEF7",
                width: "100%",
                fontSize: "14px",
                color: "#4B4F55",
              }}
              key={entry + index}
            >
              {entry}
            </Space>
          ))}
      </div>
    );
  }

  return (
    <>
      <Drawer
        styles={{
          // header: { display: "none" },
          body: { padding: "0 48px 48px" },
        }}
        open={open}
        onClose={() => close()}
        width={650}
      >
        <div
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 10,
            paddingTop: "15px",
          }}
        >
          <Space
            style={{
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "15px",
            }}
          >
            <Typography.Title level={2} style={{ margin: "0px 0px 5px 0px" }}>
              v{dataDetail?.release_version}
            </Typography.Title>
            <Space>
              <CalendarOutlined style={{ fontSize: "24px" }} />
              <Typography.Title level={2} style={{ margin: "0px 0px 5px 0px" }}>
                {dataDetail?.release_date}
              </Typography.Title>
            </Space>
          </Space>
          <Space
            style={{
              gap: "32px",
              marginBottom: 20,
              width: "100%",
              justifyContent: "end",
            }}
          >
            <a href="#release_notes">Описание релиза</a>
            {dataDetail?.known_issues && (
              <a href="#known_issues">Известные проблемы</a>
            )}
          </Space>
        </div>
        <div
          style={{
            width: "100%",
            overflowY: "auto",
          }}
        >
          {loader && <PreloaderNew />}
          <Typography.Title level={5} id="release_notes">
            Описание релиза
          </Typography.Title>
          {parseReleaseNotes(dataDetail?.release_notes) || "Описания нет"}
          {dataDetail?.known_issues && (
            <>
              <Typography.Title
                level={5}
                id="known_issues"
                style={{ marginTop: 15 }}
              >
                Известные проблемы
              </Typography.Title>
              {parseReleaseNotes(dataDetail?.known_issues) || "Данных нет"}
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};
