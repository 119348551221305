import React, { useEffect, useState } from "react";
import styles from "./EdlStyles.module.css";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Switch,
  Table,
  message,
} from "antd";
import {
  IconClose,
  IconDelete,
  IconEdit,
  IconSearch,
  IconCopy,
} from "../../icons";
import {Typography} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getEdlDataDetailList,
  getEdlDataInstance,
  getEdlSuccessActivateList,
  getEdlSuccessAdd,
  getEdlSuccessAddList,
  getEdlSuccessDelete,
  getEdlSuccessPatch,
  getEdlTaskCheckErrors,
  getInstanceLoaders,
  getValidatorApplySuccess,
  getValidatorError,
  getValidatorLoaders,
  getValidatorSuccess,
} from "../../store/edl/selectors";
import {
  checkEdlListValidators,
  getCheckCloseInstance,
  getEdlDetailList,
  getEdlUser,
  resetValidatorsError,
  switchValidateSuccess,
} from "../../store/edl";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import ModalConfirm from "./ModalConfirm";
import { IconPlus } from "../../icons/IconPlus";
import ModalConfirmInctance from "./ModalConfirmInctance";
import ModalAddLInctance from "./ModalAddLInctance";
import ModaLPatchInctance from "./ModaLPatchInctance";
import ModalCheckValidate from "./ModalCheckValidate";

function ModalListDetail({ open, close, itemId, user, permissions }) {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const listDetail = useSelector(getEdlDataDetailList);
  const [active, setActive] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalAddFile, setModalAddFile] = useState(false);
  const [modalPatch, setModalPatch] = useState(false);
  const isSuccessActivate = useSelector(getEdlSuccessActivateList);
  const isSuccessDelete = useSelector(getEdlSuccessDelete);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const dataListInstance = useSelector(getEdlDataInstance);
  const isLoading = useSelector(getInstanceLoaders);
  const addSuccess = useSelector(getEdlSuccessAdd);
  const patchSuccess = useSelector(getEdlSuccessPatch);
  const [copyText, setCopyText] = useState(null);
  const [currentInst, setCurrentInst] = useState(null);
  const errors = useSelector(getEdlTaskCheckErrors);
  const [messageApi] = message.useMessage();
  const [renew, setRenew] = useState(false);
  const addSuccessFile = useSelector(getEdlSuccessAddList);
  const [form] = Form.useForm();
  const [changeValidators, setChangeValidators] = useState(false);
  const successValidate = useSelector(getValidatorSuccess);
  const [openModal, setOpenModal] = useState(false);
  const validatorLoader = useSelector(getValidatorLoaders);
  const [validators, setValidators] = useState([]);
  const validatorError = useSelector(getValidatorError);
  const validatorApplySuccess = useSelector(getValidatorApplySuccess);
  useEffect(() => {
    if (successValidate) {
      setOpenModal(true);
      dispatch(switchValidateSuccess());
    }
  }, [successValidate]);

  useEffect(() => {
    if (copyText) messageApi.info(copyText.text);
  }, [copyText]);

  const fetchData = () => {
    dispatch(getEdlDetailList(itemId));
    dispatch(getEdlUser());
  };

  useEffect(() => {
    if (
      isSuccessActivate === false &&
      isSuccessDelete === false &&
      addSuccess === false &&
      validatorApplySuccess === false
    )
      fetchData();
  }, [
    itemId,
    isSuccessActivate,
    isSuccessDelete,
    addSuccess,
    validatorApplySuccess,
  ]);
  const fetchDataList = () => {
    if (
      isSuccessDelete === false &&
      addSuccess === false &&
      patchSuccess === false &&
      addSuccessFile === false
    )
      dispatch(
        getCheckCloseInstance({
          idEdl: itemId,
          pageInstance: page,
          searchText: searchText,
        })
      );
    // dispatch(getEdlDetailInstance({ idEdl: idEdl, pageInstance: page + 1, searchText: searchText }));
  };
  useEffect(() => {
    const timerRefresh = setTimeout(fetchDataList, 1000);
    return () => {
      clearTimeout(timerRefresh);
    };
  }, [
    dispatch,
    itemId,
    page,
    searchText,
    isSuccessDelete,
    addSuccess,
    patchSuccess,
    renew,
    addSuccessFile,
  ]);

  useEffect(() => setActive(listDetail?.is_active), [listDetail]);

  const columns = [
    createColumn("Значение", "value", (text) => (
      <div
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        title={text}
      >
        {text?.length > 40 ? "..." + text.slice(-35) : text}
        <Button
          size="small"
          type="text"
          icon={<IconCopy style={{ color: "#8090B8" }} />}
          onClick={() => {
            copyBufferText(text);
          }}
        />
      </div>
    )),
    createColumn("Комментарий", "comment", (text) => (
      <div
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        title={text}
      >
        {text?.slice(0, 35)}
        {text?.length > 35 ? "..." : ""}
      </div>
    )),
    createColumn("Изменено", "date_modified", (text, row) => (
      <div
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        title={text}
      >
        {text}
        <br />
        {row["updated_by"]}{" "}
      </div>
    )),
    {
      title: "",
      key: `actions`,
      dataIndex: ["file", "id", "name"],
      render: (text, row) => {
        return (
          <Space style={{ background: "none !important" }}>
            {permissions.full_access && (
              <Button
                size="small"
                type="text"
                icon={<IconEdit style={{ color: "#8090B8" }} />}
                onClick={() => {
                  setCurrentInst(row);
                  setModalPatch(true);
                }}
              />
            )}
            {permissions?.full_access && (
              <Button
                size="small"
                type="text"
                icon={<IconDelete style={{ color: "#8090B8" }} />}
                onClick={() => setModalConfirmDelete({ open: true, item: row })}
              />
            )}
          </Space>
        );
      },
    },
  ];
  const copyBufferText = (text, field) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyText({ text: "Скопировано", field: field });
      })
      .catch(() => {
        setCopyText({ text: "Ошибка", field: field });
      });
  };

  useEffect(() => {
    form.resetFields();
    dispatch(resetValidatorsError());
    if (listDetail) {
      if (listDetail.validators.length === 0) {
        form.setFields([{ name: "none", value: true }]);
      } else {
        const arr = listDetail.validators.map((item) => {
          return { name: item, value: true };
        });
        form.setFields(arr);
      }
    }
  }, [listDetail, open]);

  const handleValidateList = (data) => {
    let arr = [];
    if (data.none !== true) {
      for (const [key, value] of Object.entries(data)) {
        if (value === true) arr.push(key);

      }
    }
    setValidators(arr);
    dispatch(checkEdlListValidators({ idList: itemId, validators: arr }));
  };
  return (
    <Drawer
      open={open}
      onClose={() => {
        close();
      }}
      width={700}
      style={{ position: "relative" }}
      maskClosable={false}
      styles={{ header: { display: "none" } }}
    >
      <div
        style={{
          zIndex: 40000,
          background: "rgb(22, 119, 255, .2)",
          height: "100%",
          width: "100%",
          position: "absolute",
          display: validatorLoader ? "block" : "none",
        }}
      ></div>

      {openModal && (
        <ModalCheckValidate
          open={openModal}
          close={() => setOpenModal(false)}
          name={listDetail?.name}
          validators={validators}
          idList={listDetail.id}
        />
      )}
      {modalAdd && (
        <ModalAddLInctance
          open={modalAdd}
          close={() => setModalAdd(false)}
          item={listDetail}
          type="single"
        />
      )}
      {modalAddFile && (
        <ModalAddLInctance
          open={modalAddFile}
          close={() => setModalAddFile(false)}
          item={listDetail}
          type="file"
        />
      )}
      {modalPatch && (
        <ModaLPatchInctance
          open={modalPatch}
          close={() => setModalPatch(false)}
          item={currentInst}
          list={listDetail}
        />
      )}
      {
        <ModalConfirm
          open={modalConfirmOpen?.open}
          close={() => setModalConfirmOpen(false)}
          action={modalConfirmOpen?.action}
          item={modalConfirmOpen.item}
        />
      }
      {
        <ModalConfirmInctance
          open={modalConfirmDelete.open}
          close={() => setModalConfirmDelete(false)}
          item={modalConfirmDelete.item}
          list={itemId}
          user={user}
        />
      }
      <Space
        style={{
          display: "flex",
          justifyContent: "end",
          paddingInline: 16,
          height: 56,
        }}
      >
        <Button
          onClick={close}
          icon={<IconClose style={{ width: 12, height: 12 }} />}
          type="ghost"
          style={{
            width: 32,
            height: 32,
            backgroundColor: "#F6F8FD",
            padding: 0,
          }}
        />
      </Space>
      <Space
        direction="vertical"
        size={24}
        style={{
          paddingInline: 48,
          paddingBottom: 32,
          display: "flex",
          position: "relative",
        }}
      >
        {validatorLoader && <PreloaderNew />}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Title level={2} style={{ marginBottom: 0 }}>
            {listDetail?.name}{" "}
            {listDetail?.is_demo && (
              <span
                style={{
                  fontSize: "30px",
                  background: `rgb(0, 0, 128)`,
                  borderRadius: "4px",
                  padding: "2px",
                  textAlign: "center",
                  color: "white",
                  width: "100px",
                }}
              >
                {"DEMO"}
              </span>
            )}
          </Typography.Title>
          <Button
            style={{ background: "#2E496F", width: "100px" }}
            type="primary"
            disabled={isLoading ? true : false}
            onClick={() => {
              fetchData();
              setRenew(!renew);
            }}
          >
            Обновить
          </Button>
        </div>
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                itemColor: "#8090B8",
                itemHoverColor: "#333333",
                itemSelectedColor: "#333333",
                inkBarColor: "#333333",
              },
            },
          }}
        ></ConfigProvider>
        {permissions?.full_access && (
          <Col span={8}>
            <Switch
              checked={active}
              disabled={addSuccess}
              style={{ width: "44px" }}
              checkedChildren=""
              unCheckedChildren=""
              onChange={() => {
                setModalConfirmOpen({
                  open: true,
                  action: "switchActive",
                  item: listDetail,
                });
              }}
            ></Switch>
            <Text
              style={{
                marginLeft: "12px",
                color: "#7A7D86",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              {active ? "Активен" : "Неактивен"}
            </Text>
          </Col>
        )}

        {validatorLoader && (
          <Alert
            style={{ fontSize: "40px" }}
            type="info"
            message="Ожидайте окончания проверки списка"
          />
        )}
        {validatorError && (
          <Alert message={validatorError} type="error" showIcon />
        )}
        <div
          className={styles.detailWrapper}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Row>
            <Col span={10}>
              <span className={styles.detailTitle}>Тип списка</span>
            </Col>
            <Col span={14}>
              <span className={styles.detailText}>{listDetail?.list_type}</span>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <span className={styles.detailTitle}>Создан</span>
            </Col>
            <Col span={14}>
              <span className={styles.detailText}>
                {" "}
                {listDetail?.date_created}
              </span>
              <span className={styles.detailTitle}>
                {" "}
                {listDetail?.created_by}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <span className={styles.detailTitle}>Изменен</span>
            </Col>
            <Col span={14}>
              <span className={styles.detailText}>
                {listDetail?.date_modified}
              </span>
              <span className={styles.detailTitle}>
                {" "}
                {listDetail?.updated_by}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <span className={styles.detailTitle}>Записей</span>
            </Col>
            <Col span={14}>
              <span className={styles.detailText}>
                {!listDetail?.is_external
                  ? dataListInstance?.count
                  : listDetail?.instance_count}{" "}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <span className={styles.detailTitle}>Ссылка</span>
            </Col>
            <Col span={14}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className={styles.detailText}>
                  {listDetail?.list_url.slice(0, 45)}{" "}
                </span>
                <span className={styles.detailText}>
                  {listDetail?.list_url.slice(45)}{" "}
                  <Button
                    size="small"
                    type="text"
                    icon={<IconCopy style={{ color: "#8090B8" }} />}
                    onClick={() => {
                      copyBufferText(listDetail?.list_url, "main");
                    }}
                  />
                  {copyText?.text && copyText.field === "main" && (
                    <span style={{ color: "green" }}>{copyText.text}</span>
                  )}
                </span>
              </div>{" "}
            </Col>
          </Row>
          <hr />

          {!listDetail?.is_external && (
            <Row>
              <Col span={10}>
                <span className={styles.detailTitle}>Валидаторы</span>
              </Col>
              <Col span={14}>
                <Form
                  form={form}
                  style={{ display: "flex", flexDirection: "column" }}
                  onFinish={(data) => {
                    handleValidateList(data);
                  }}
                >
                  <Space>
                    <Space direction="vertical" align="center">
                      <span style={{ fontSize: "12px", color: "#7A7D86" }}>
                        Palo Alto
                      </span>
                      <Form.Item
                        name="PAN"
                        valuePropName="checked"
                        onChange={() => {
                          setChangeValidators(true);
                          form.setFields([{ name: "none", value: false }]);
                        }}
                      >
                        <Checkbox disabled={!permissions?.full_access} />
                      </Form.Item>
                    </Space>

                    <Space direction="vertical" align="center">
                      <span style={{ fontSize: "12px", color: "#7A7D86" }}>
                        Fortinet
                      </span>
                      <Form.Item
                        name="FORTINET"
                        valuePropName="checked"
                        onChange={() => {
                          setChangeValidators(true);
                          form.setFields([{ name: "none", value: false }]);
                        }}
                      >
                        <Checkbox disabled={!permissions?.full_access} />
                      </Form.Item>
                    </Space>

                    {/* <Space direction='vertical' align='center'>
		<span style={{fontSize: '12px', color: '#7A7D86'}}>CheckPoint</span>
		<Form.Item name='CP' valuePropName='checked'  disabled={true} onChange={()=> {setChangeValidators(true);
			form.setFields([{'name': 'none', value: false}])
		}}>
		<Checkbox  disabled={true}/></Form.Item >
	</Space> */}

                    <Space direction="vertical" align="center">
                      <span style={{ fontSize: "12px", color: "#7A7D86" }}>
                        NONE
                      </span>
                      <Form.Item
                        name="none"
                        valuePropName="checked"
                        onChange={() => {
                          setChangeValidators(true);
                          form.setFields([
                            { name: "PAN", value: false },
                            { name: "FORTINET", value: false },
                            { name: "CP", value: false },
                          ]);
                        }}
                      >
                        <Checkbox disabled={!permissions?.full_access} />
                      </Form.Item>
                    </Space>
                  </Space>
                  {changeValidators && (
                    <Button
                      type="primary"
                      style={{ width: "200px" }}
                      htmlType="submit"
                    >
                      Проверить список
                    </Button>
                  )}
                </Form>
              </Col>
            </Row>
          )}
        </div>
        {listDetail?.limits_exceeded_msg !== null && (
          <Alert
            message="Warning"
            description={listDetail?.limits_exceeded_msg}
            type="warning"
            showIcon
            closable
          >
            {listDetail?.limits_exceeded_msg}
          </Alert>
        )}
        {errors && (
          <Alert
            message="Warning"
            description={errors}
            type="warning"
            showIcon
            closable
          >
            {listDetail?.limits_exceeded_msg}
          </Alert>
        )}
        {!listDetail?.is_external && (
          <>
            <Space>
              {permissions?.full_access && (
                <Button
                  className={styles.addButton}
                  style={{
                    backgroundColor: "transparent",
                    // display: !true ? "none" : "",
                    width: "185px",
                  }}
                  onClick={() => setModalAdd(true)}
                  disabled={addSuccess || errors}
                >
                  <IconPlus />
                  Добавить запись
                </Button>
              )}
              {permissions?.full_access && (
                <Button
                  className={styles.addButton}
                  style={{
                    backgroundColor: "transparent",
                    // display: !true ? "none" : "",
                    width: "185px",
                  }}
                  onClick={() => setModalAddFile(true)}
                  disabled={addSuccess || errors}
                >
                  <IconPlus />
                  Загрузить файл
                </Button>
              )}
            </Space>
            <Form
              layout="inline"
              style={{
                width: "100%",
                flexWrap: "nowrap",
                position: "relative",
              }}
              requiredMark={false}

              // onFinish={({ search }) => {

              // 	setSearchText(search);

              // }}
            >
              {isLoading && <PreloaderNew />}
              {/* <Form.Item name="search" style={{ flexGrow: 1, marginInlineEnd: 20, width: '506px' }} className={styles.inputUrl} value={searchText}>
							<Input
								required={true}
								onChange={(evt) => {  setSearchText(evt.target.value) }}
								value={searchText}
								prefix={<IconSearch style={{ color: "#C1C2C9" }} />}
								// value={searchText}
								suffix={<IconClose onClick={() => setSearchText('')} />} style={searchText ? { 'color': 'red' } : { 'color': 'black' }}
								placeholder="Поиск записи в списке"


							/>
						</Form.Item> */}
              <Input
                prefix={<IconSearch style={{ color: "#C1C2C9" }} />}
                value={searchText}
                placeholder="Поиск записи в списке"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                suffix={<IconClose onClick={() => setSearchText("")} />}
                style={searchText ? { color: "red" } : { color: "black" }}
              />
              {/* <Button style={{ flexGrow: 1, background: "#2E496F" }}
							type="primary"
							htmlType="submit"
							disabled={isLoading ? true : false}


						>
							Найти
						</Button> */}
            </Form>

            <Table
              columns={columns}
              dataSource={dataListInstance?.results}
              className={styles.instanceForm}
              rowKey={(obj) => obj.id}
              size="small"
              pagination={{
                showSizeChanger: false,
                position: "bottomRight",
                defaultPageSize: 20,
                total: dataListInstance?.count,
                onChange: (page) => setPage(page),
              }}
            />
          </>
        )}
      </Space>
    </Drawer>
  );
}

function createColumn(title, key, render) {
  return { title, key, dataIndex: key, render };
}

export default ModalListDetail;
