import React from 'react'
import { useRef, useEffect, useState } from "react";
import styles from "./Distributions.module.css";
import { Table, Typography, Button, Badge } from "antd";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { IconDownloadFile } from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentsList } from "../../store/distributions";
import { getLoadersDoc, getLoaders } from "../../store/documentation/selectors";
import {
  getDocumentNextPage,
  getDocumentDistributionsData,
} from "../../store/distributions/selectors";
import { bytesToSize } from "../../utils/converterBytes";
import { viewExtension } from "./utils";

const { Title } = Typography;

export const DistributionsDocTable = ({ downloadFile, createColumn }) => {
  const dispatch = useDispatch();
  const lastItem = useRef();
  const observer = useRef();
  const [needToLoad, setNeedToLoad] = useState(false);
  const nextPage = useSelector(getDocumentNextPage);
  const isLoader = useSelector(getLoaders);
  const isLoaderDoc = useSelector(getLoadersDoc);
  const docData = useSelector(getDocumentDistributionsData);

  const columns = [
    createColumn("", "name", false, 100),
    createColumn("", "date_modified", false, 100),
    createColumn(
      "",
      "date_modified",
      (_, row) => (
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <p style={{ margin: 0 }}>
            {<Badge count={viewExtension(row?.doc_file)} color="rgb(22, 119, 255)" />}
            <span style={{ color: "#7A7D86", marginLeft: "7px" }}>
              ({bytesToSize(row?.doc_size)})
            </span>
          </p>
        </div>
      ),
      100,
      false
    ),
    createColumn(
      "",
      "doc_file",
      (text, row) => (
        <Button
          size="small"
          type="text"
          style={{ background: "none" }}
          icon={<IconDownloadFile className={styles.icon} />}
          onClick={() => {
            downloadFile(row);
          }}
          title={text}
        ></Button>
      ),
      10,
      "right"
    ),
  ];

  const fetchData = (pagination = false) => {
    // let filters = {};
    // if (search) {
    //   filters = {
    //     search: search,
    //   };
    // }
    dispatch(
      getDocumentsList({
        page: pagination ? nextPage : 1,
        pagination: pagination,
      })
    );
  };

  useEffect(() => {
    if (isLoaderDoc || isLoader) return;
    if (observer?.current) observer?.current.disconnect();
    let callback = function (
      entries
      //observer
    ) {
      if (entries[0].isIntersecting && nextPage && docData?.length > 0) {
        setNeedToLoad(true);
      }
    };

    observer.current = new IntersectionObserver(callback);
    observer.current.observe(lastItem?.current);
  }, [isLoaderDoc, isLoader]);

  useEffect(() => {
    (async () => {
      if (needToLoad && nextPage) {
        fetchData(true);
        setNeedToLoad(false);
      }
    })();
  }, [needToLoad]);

  useEffect(() => {
    const timerRefresh = setTimeout(fetchData, 500);
    return () => {
      clearTimeout(timerRefresh);
    };
  }, []);

  return (
    <>
      <div className={styles.descriptions}>
        <Title level={3} id="section2" style={{ margin: 0 }}>
          Документы и утилиты
        </Title>
        <div style={{ height: "400px", width: "100%", overflowY: "auto" }}>
          <Table
            loading={{
              indicator: <PreloaderNew />,
              spinning: isLoaderDoc || isLoader,
            }}
            columns={columns}
            rowKey={(obj) => obj.id}
            size="small"
            pagination={false}
            style={{ overflowX: "auto" }}
            className={styles.table}
            dataSource={docData}
          />{" "}
          <div ref={lastItem} style={{ height: 20 }} />
        </div>
      </div>
    </>
  );
};
