import React, { useEffect, useRef, useState } from "react";
import { Space, Typography, message, Card, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Sandbox.module.css";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import WithoutAuthorization from "../../components/WithoutAuthorization";
import SandboxList from "./SandboxList";
import {
  getSandboxList,
  getSandboxMyAssets,
  getSandboxStats,
} from "../../store/sandbox";
import {
  getSandboxData,
  getSandboxFileErrors,
  getSandboxLoaders,
  getSandboxNextPage,
  getSandboxStatsData,
} from "../../store/sandbox/selectors";
const { Title, Text } = Typography;
const styleTextGray = { color: "#7A7D86" };

function SandboxArea({ permissions }) {
  const [, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const dataStats = useSelector(getSandboxStatsData);
  const isLoader = useSelector(getSandboxLoaders);
  message.config({ duration: 5 });
  const fileError = useSelector(getSandboxFileErrors);
  const [search, setSearch] = useState("");
  const [verdict, setVerdict] = useState(null);
  const [source, setSource] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [renew, setRenew] = useState(false);
  const [serial, setSerial] = useState(null);
  const [needToLoad, setNeedToLoad] = useState(false);
  const nextPage = useSelector(getSandboxNextPage);

  const assetsData = useSelector(getSandboxData);
  const lastItem = useRef();
  const observer = useRef();
  const fetchData = (pagination = false) => {
    let filters = false;
    if (search || verdict || fileType || serial || source)
      filters = {
        search: search,
        verdict__in: verdict === null ? null : verdict?.join(","),
        file_type__in: fileType === null ? null : fileType.join(","),
        firewall_id: serial,
        source: source,
      };
    if (permissions?.full_access || permissions?.read_access) {
      dispatch(
        getSandboxList({
          filters: filters,
          page: pagination ? nextPage + 1 : null,
          pagination: pagination,
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      if (needToLoad && nextPage) {
        fetchData(true);
        setNeedToLoad(false);
      }
    })();
  }, [needToLoad]);

  useEffect(() => {
    if (isLoader) return;
    if (observer?.current) observer?.current.disconnect();
    let callback = function (
      entries
      //observer
    ) {
      if (entries[0].isIntersecting && nextPage && assetsData?.length > 0) {
        setNeedToLoad(true);
      }
    };
    observer.current = new IntersectionObserver(callback);
    observer.current.observe(lastItem?.current);
  }, [isLoader]);

  useEffect(() => {
    if (permissions?.full_access || (permissions?.read_access && !renew)) {
      const timerRefresh = setTimeout(fetchData, 500);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [search, verdict, renew, fileType, serial, permissions, source]);

  useEffect(() => {
    if (permissions?.full_access || permissions?.read_access) {
      dispatch(getSandboxMyAssets());
      dispatch(getSandboxStats());
    }
  }, [permissions, renew]);

  return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
      {contextHolder}
      <Title level={3} style={{ margin: 0 }}>
        SANDBOX
      </Title>
      {(permissions?.full_access || permissions?.read_access) && (
        <div style={{ opacity: isLoader ? 0.3 : 1 }}>
          {fileError && (
            <Alert message={fileError} type="error" key={"error"} showIcon>
              {fileError}
            </Alert>
          )}
          <Card style={{ position: "relative" }}>
            <div data-testid = 'cards-area' className={styles.limitsCards}>
              <div>
                <Text>Проверено файлов</Text>
                <Text style={styleTextGray}>
                  Сегодня: {dataStats?.checked_files_today}{" "}
                </Text>
                <Text style={styleTextGray}>
                  Всего: {dataStats?.checked_files_total}{" "}
                </Text>
              </div>
              <div>
                <div
                  style={{
                    backgroundColor: "#E0F6EA",

                    color: "#219653",

                    display: "inline",
                    borderRadius: 2,
                    padding: "0",
                    paddingLeft: 0,
                    textTransform: "uppercase",
                  }}
                >
                  BENIGN
                </div>
                <Text style={styleTextGray}>
                  Сегодня: {dataStats?.bening_files_today}
                </Text>
                <Text style={styleTextGray}>
                  Всего: {dataStats?.bening_files_total}{" "}
                </Text>
              </div>
              <div>
                <div
                  style={{
                    backgroundColor: "#FFECEC",
                    color: "#EB5757",

                    display: "inline",
                    borderRadius: 2,
                    padding: "0",
                    paddingLeft: 0,
                    textTransform: "uppercase",
                  }}
                >
                  MALWARE
                </div>
                <Text style={styleTextGray}>
                  Сегодня: {dataStats?.malware_files_today}
                </Text>
                <Text style={styleTextGray}>
                  Всего: {dataStats?.malware_files_total}
                </Text>
              </div>
            </div>
            {isLoader && <PreloaderNew />}
          </Card>
          <SandboxList
            fetchData={fetchData}
            setSearch={setSearch}
            setVerdict={setVerdict}
            search={search}
            verdict={verdict}
            renew={renew}
            setRenew={setRenew}
            fileType={fileType}
            setFileType={setFileType}
            serial={serial}
            setSerial={setSerial}
            lastItem={lastItem}
            source={source}
            setSource={setSource}
          />
        </div>
      )}
      <div ref={lastItem} style={{ height: 20 }} />
      {permissions?.full_access === false &&
        permissions?.read_access === false && <WithoutAuthorization />}
    </Space>
  );
}

export default SandboxArea;
